.App {
  /* position: "relative"; */
  /* background-image: url("./shulKutaisi/IMG_3616.jpg"); */
  background-size: cover; /* <------ */
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex-shrink: 10;
  /* max-height: 100vh; */
  overflow: hidden;
  font-family: "Gowun Dodum", sans-serif;
}

.fixed-header-split {
  display: flex;
  flex-direction: row;
  height: 65px;
}

.fixed-header {
  position: fixed;
  width: 100%;
  background-color: black;
  top: 0;
  width: 50%;
  z-index: 15;
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-top: 1px solid black;
  margin: 0;
  height: 65px;
  padding: 2px 5px;
  /* padding: 10px 0; */
}

.fixed-header-mobile {
  position: fixed;
  width: 100%;
  background-color: black;
  top: 0;
  min-width: 100vw;
  z-index: 15;
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-top: 1px solid black;
  margin: 0;
  height: 65px;
  padding: 2px 5px;
  /* padding: 10px 0; */
}

.fixed-header-2 {
  position: fixed;
  width: 100%;
  background-color: black;
  top: 0;
  width: 50%;
  z-index: 15;
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: row;
  /* justify-content: right; */
  align-items: center;
  border-top: 1px solid black;
  margin: 0;
  height: 65px;
  padding: 2px 5px;
  /* padding: 10px 0; */
}

.lang-option:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
}

.header-link {
  color: white;
  padding: 0px;
  margin: 0px;
}

.link {
  text-decoration: none;
}
.link:hover {
  text-decoration: underline;
  text-decoration-color: white;
}

.photome:hover {
  text-decoration: underline;
  text-decoration-color: black;
}

.websiteme {
  color: white;
}

.websiteme:hover {
  text-decoration: underline;
  text-decoration-color: white;
}

.slider {
  background-color: white;
  position: relative;
  /* height: 100vh; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-height: 100vh;
  margin: 0;
  padding-bottom: 0;
  z-index: 1;
}
.slider-mobile {
  background-color: white;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-height: 100vh;
  z-index: 1;
  margin-top: 35px;
}

.image {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  min-width: 100vw;
  z-index: 0;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: 32px;
  font-size: 3rem;
  color: black;
  z-index: 2;
  cursor: pointer;
  user-select: none;
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: 32px;
  font-size: 3rem;
  color: black;
  z-index: 2;
  cursor: pointer;
  user-select: none;
}
.right-arrow-mobile {
  position: absolute;
  top: 50%;
  right: 15px;
  font-size: 3rem;
  color: black;
  z-index: 2;
  cursor: pointer;
  user-select: none;
}

.left-arrow-mobile {
  position: absolute;
  top: 50%;
  left: 15px;
  font-size: 3rem;
  color: black;
  z-index: 2;
  cursor: pointer;
  user-select: none;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}

.card {
  opacity: 0;
  transform: translateX(-100%);
  transition: all 0.4s ease-in-out;
  z-index: 14;
  /* transition-duration: 0.7s;
  transform: scale(0); */
}
.card.active {
  opacity: 0.95;
  transform: translateX(0%);
  transition: all 0.4s ease-in-out;
  z-index: 14;
  /* transition-duration: 0.7s;
  transform: scale(1.08); */
}

.numberIndicator {
  position: absolute;
  bottom: 32px;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.numberIndicator-mobile {
  position: absolute;
  bottom: 15px;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.text-1 {
  width: 100%;
  padding: 0;
  margin: 0;
  height: 850px;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) -10%,
    rgba(222, 153, 49, 1) 100%
  );
}
.text-1-mobile {
  width: 100vw;
  padding: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) -10%,
    rgba(222, 153, 49, 1) 100%
  );
  /* background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(222, 153, 49, 1) 100%
  ); */
  border-bottom: white;
}

.text-button:hover {
  height: 42px;
  width: 152px;
  cursor: pointer;
  margin-top: 18px;
}

.quote {
  width: 100%;
  height: 350px;
  font-family: "MonteCarlo", cursive;
  font-size: x-large;
}

.quote {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.gallery {
  background-color: rgb(255, 255, 255);
  /* background: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(222, 153, 49, 1) 100%
  ); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 200px;
  padding-top: 100px;
  flex-shrink: 2;
}
.gallery-mobile {
  background-color: rgb(255, 255, 255);
  /* background: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(222, 153, 49, 1) 100%
  ); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;
  padding-top: 100px;
  flex-shrink: 2;
  border-top: white;
}

.gallery-image {
  width: 300px;
  padding: 0;
  /* border: 3px solid #de9931; */
  vertical-align: top;
  margin: 15px;
}
.gallery-image-mobile {
  width: 90%;
  padding: 0;
  margin: 7.5px 15px;
}

.gallery-image:hover {
  width: 310px;
  padding: 0;
  /* border: 3px solid #de9931; */
  vertical-align: top;
  margin: 9px 10px 14px;
}

.contact-all {
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) -100%,
    rgba(222, 153, 49, 1) 100%
  );
}

.contact-all-mobile {
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) -100%,
    rgba(222, 153, 49, 1) 100%
  );
}

.contact-data-segment-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 30px;
  margin-left: 30px;
}

.contact-data-segment-child {
  margin: 40px 15px;
}

a {
  text-decoration: none;
}

.contact-info-second-part {
  position: relative;
  height: 200px;
  background-image: url("./shulKutaisi/IMG_3619.jpg");
  background-size: cover; /* <------ */
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
}

.contact-info-second-part-2 {
  position: "absolute";
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 6;
}

.contact-title {
  margin: 15px;
  text-align: center;
  font-weight: 800;
}

.footer {
  padding: 10px;
  color: lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 1);
}

.footer-mobile {
  padding: 10px;
  color: lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 1);
  flex-shrink: 5;
}

.pl {
  color: black;
}

.pl:link {
  color: black;
}

.pl:visited {
  color: black;
}

.pl:hover {
  color: black;
}

.pl:active {
  color: black;
}
